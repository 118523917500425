import { createGlobalStyle } from "styled-components"

export const colors = {}
colors.background = "#F5F5F5"
colors.text = "#4a5259"

export const size = {
    width: "850px",
    topHeight: "370px",
}

const GlobalStyle = createGlobalStyle`
    :root {
        --width: ${size.width};
        --topHeight: ${size.topHeight};
    }
    body{
        color: ${colors.text};
    }

    blockquote {
        font-size: 100%;
        color: rgba(60, 74, 96, 0.7);
        border-left: 5px solid rgba(0, 0, 0, 0.05);
        background-color: rgba(0,0,0,0.03);
        margin-bottom: 30px;
        padding: 3%;
    }
`

export default GlobalStyle