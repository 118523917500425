import React from "react"
import Footer from './footer'
import GlobalStyle from '../styles/global-style'
import Header from './header'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--background);
    .inner {
      width: 100%;
      padding: 0 10px;
      position: relative;
    }
  }
`

const Layout = ({ location, title, children }) => {
    return (
        <Wrapper>
            <Header />
            <main className="content" style={{
                paddingTop: `100px`,
                paddingBottom: `100px`
            }} >{children}</main>
            <Footer />
            <GlobalStyle />
        </Wrapper>
    )
}

export default Layout
