import Typography from "typography"
import oceanBeachTheme from "typography-theme-ocean-beach"

oceanBeachTheme.overrideThemeStyles = () => {
    return {
        "a.gatsby-resp-image-link": {
            boxShadow: `none`,
        },
        // baseFontSize: "16px"
        //     baseLineHeight: 1.5,
        //     googleFonts: [  {
        //       name: 'Noto+Sans+JP',
        //       styles: ['400'],
        //     }],
        //     headerFontFamily: ["Noto Sans JP", "sans-serif"],
        //     bodyFontFamily: ["Hiragino Kaku Gothic ProN", "Noto Sans JP", "sans-serif"],
        //     headerColor: "rgb(240,240,240)",
        //     bodyColor: "rgb(0,240,240)",
        //   }
    }
}

// oceanBeachTheme.bodyFontFamily = ['Noto Sans JP']

const opts = {
    baseFontSize: `16px`,
    baseLineHeight: 1.66,
    scaleRatio: 2.557,
    headerFontFamily: [
        'Hiragino Kaku Gothic Pro',
        "游ゴシック体",
        "Yugothic",
        "游ゴシック",
        "Yu Gothic",
        "Noto Sans JP",
        "Noto Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        "Roboto",
        "Helvetica Neue",
        "メイリオ",
        "sans-serif"],
    bodyFontFamily: [
        'Hiragino Kaku Gothic Pro',
        "游ゴシック体",
        "Yugothic",
        "游ゴシック",
        "Yu Gothic",
        "Noto Sans JP",
        "Noto Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        "Roboto",
        "Helvetica Neue",
        "メイリオ",
        "sans-serif"],
    // bodyWeight: 500,
    headerWeight: 700,
}

const typography = new Typography(opts);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
    typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
