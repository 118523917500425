import React from "react"
import { useSiteMetadata } from "./queries"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'

const Wrapper = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    color: #ffffff;
    z-index: 99999;
    height: 50px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .5);
    background: #355b73;
    a:link{
        color: inherit;
        text-decoration: none;
    }
    a:visited{
        color: inherit;
        text-decoration: none;
    }
    a:hover{
        color: #4e85a6;
        text-decoration: underline;
    }
    a:active{
        color: inherit;
    }
    .container {
        padding-left: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        width: var(--width);
        position: relative;
        z-index: 1;
        .logo-box {
            position: relative;
            height: 100%;
            display: flex;
            .logo{
                margin: auto;
                color: inherit;
                font-size: 1.3rem;
                font-weight: 600;
                &:hover{
                    text-decoration: none;
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
        .right-box {
            height: 100%;
            margin-left: auto;
            display: flex;
            align-items: flex-start;
            a {
                color: inherit;
                padding-right: 15px;
                padding-left: 15px;
                margin: auto;
                &:hover{
                    text-decoration: none;
                    background-color: rgba(255, 255, 255, 0.2);
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }

    }
`

const Header = props => {
    const { title, social } = useSiteMetadata()
    return (
        <Wrapper>
            <div className="container">
                <div className="logo-box">
                    <Link to="/" className="logo">
                        {title}
                    </Link>
                </div>
                <div className="right-box">
                    <a
                        href={`https://github.com/${social.github}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                    <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a
                        href={`https://twitter.com/${social.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                    <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                        href={`/about`}
                        rel="noopener noreferrer">
                    about
                    </a>
                </div>

            </div>
        </Wrapper>
    )
}

export default Header